import {
  BRow,
  BCol,
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormTextarea,
  BSpinner,
  BCollapse,
  BLink,
  BButton,
  BFormRadio,
  BFormCheckbox,
  BTooltip,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  VBToggle,
} from 'bootstrap-vue'
import {
  required,
  max,
  integerDouble,
} from '@validations'

import { v4 as uuidv4 } from 'uuid'
import { mapGetters, mapActions } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

// import IconStopList from '@/components/icon/IconStopList.vue'
import IconMoveUpDown from '@/components/icon/IconMoveUpDown.vue'
import IconPreview from '@/components/icon/IconPreview.vue'
import CategoryName from '@/components/CategoryName.vue'
import CategoryTranslation from '@/components/CategoryTranslation.vue'
import ModalPreview from '@/components/modal/ModalPreview.vue'
import Translate from '@/components/Translate.vue'
import IconTranslate from '@/components/icon/IconTranslate.vue'
import AddToContentImage from '@/components/AddToContentImage.vue'
import ModalConfirmDelete from '@/components/modal/ModalConfirmDelete.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CardSchedules from '@/components/card/CardSchedules.vue'
import draggable from 'vuedraggable'
import MenuContentOffer from '@/components/MenuContentOffer.vue'
import MenuContentText from '@/components/MenuContentText.vue'
import MenuContentImage from '@/components/MenuContentImage.vue'

import mixinRequests from '@/mixins/requests'
import mixinGoogleTranslation from '@/mixins/google-translation'
import mixinTranslationsCreateUpdate from '@/mixins/translations_create-update'
import mixinTooltipCreateMenu from '@/mixins/tooltip/tooltip-create-menu'
import mixinOffer from '@/mixins/offer'
import mixinGqlCategory from '@/mixins/gql/category'

import 'lightgallery.js'
import 'lg-zoom.js'
import 'lightgallery.js/dist/css/lightgallery.css'

import UPDATE_MENU from '@/gql/mutation/menu/updateMenu.gql'
import DUPLICATE_MENU from '@/gql/mutation/menu/duplicateMenu.gql'
import DELETE_MENU from '@/gql/mutation/menu/deleteMenu.gql'
import CREATE_MENU_TRANSLATION from '@/gql/mutation/menu/createMenuTranslation.gql'
import UPDATE_MENU_TRANSLATION from '@/gql/mutation/menu/updateMenuTranslation.gql'
import GET_MENU_TRANSLATIONS from '@/gql/query/menu-managment/getMenuTranslations.gql'

export default {
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BRow,
    BCol,
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BCollapse,
    BFormRadio,
    BFormCheckbox,
    BOverlay,
    BTooltip,
    BDropdown,
    BDropdownItem,
    ModalPreview,
    IconMoveUpDown,
    IconPreview,
    CategoryName,
    CategoryTranslation,
    // IconStopList,
    ValidationObserver,
    ValidationProvider,
    BCardActions,
    Translate,
    IconTranslate,
    AddToContentImage,
    ModalConfirmDelete,
    CardSchedules,
    draggable,
    MenuContentOffer,
    MenuContentText,
    MenuContentImage,
    Treeselect,
  },
  mixins: [
    mixinRequests,
    mixinGoogleTranslation,
    mixinTranslationsCreateUpdate,
    mixinTooltipCreateMenu,
    mixinOffer,
    mixinGqlCategory,
  ],
  data() {
    return {
      isDuplicating: false,
      isFetching: true,

      // isEnabled: true,
      isVisible: true,

      isPublishing: false,
      isPublished: true,

      name: '',
      media: [],
      content: [],

      isTranslateMode: false,
      translations: [],
      translationActive: '',

      isModalConfirmDelete: false,

      textLimit: 250,

      deletingFromContent: [],

      isModalZoneEventAttach: false,
      zonesIdToAttach: [],
      eventsIdToAttach: [],
      isFutureEvent: false,
      isAttachingAndPublishing: false,
      isModalPreview: false,

      inZones: [],
      inEvents: [],

      isContentLoader: false,
      isContentValid: true,

      menuCategories: [],
      menuCategoriesTranslations: [],

      lastClickedContentRowId: '',

      addOfferParams: {
        mode: '', // '' || 'new' || 'select'
        categoryId: '',
        selectedItemsIds: [],
        newItemName: '',
      },

      isZoneEventTreeselect: false,

      createTranslationResponseObjectName: 'createMenuTranslation',
      createTranslationMutation: CREATE_MENU_TRANSLATION,
      updateTranslationMutation: UPDATE_MENU_TRANSLATION,

      // validation rules
      required,
      integerDouble,
      max,
    }
  },
  computed: {
    ...mapGetters({
      menuItems: 'menuManagement/menuItems',
      restaurant: 'restaurant/restaurant',
      menus: 'menuManagement/menus',
      isInfo: 'main/isInfo',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
      isMenusFetched: 'menuManagement/isMenusFetched',
      isZonesFetched: 'floorPlan/isZonesFetched',
      zones: 'floorPlan/zones',
      isEventsFetched: 'floorPlan/isEventsFetched',
      events: 'floorPlan/events',
      isSchedulesFetched: 'schedules/isSchedulesFetched',
    }),
    isCardAssignmentsEvents() {
      return this.inEvents.length && this.restaurant && this.restaurant.eventsSettings.enabled
    },
    previewUrl() {
      const queryParams = `restaurant=${this.restaurant ? this.restaurant.id : null}&menu=${this.$route.params.id}`
      return `${process.env.VUE_APP_ORIGIN_CLIENT}/preview/catalog?${queryParams}`
    },
    isPublishTooltip() {
      return !this.isPublished && this.isSubscriptionInactive
    },
    isDuplicate() {
      return !this.isDuplicating && !this.isCreateMenuDisabledWithoutPlan && !this.isCreateMenuDisabledWithPlan
    },
    itemsToAdd() {
      return this.menuItems.filter(i => !this.menuCategories
        .flatMap(j => j.items)
        .find(j => j.item.id === i.id))
    },
    isCardSchedules() {
      return this.isSubscriptionPremium
    },
    treeselectZoneEventOptions() {
      return [
        {
          id: 'zones',
          name: this.$t('Zones'),
          children: this.zones.map(i => ({ ...i, typeTmp: 'zone' })),
        },
        {
          id: 'events',
          name: this.$t('Events'),
          children: this.events.map(i => ({
            ...i,
            typeTmp: 'event',
            isDisabled: !(this.restaurant && this.restaurant.eventsSettings.enabled),
          })),
        },
      ]
    },
  },
  watch: {
    isMenusFetched() {
      this.fetch()
    },
    isZonesFetched() {
      this.fetch()
    },
    isEventsFetched() {
      this.fetch()
    },
    isSchedulesFetched() {
      this.fetch()
    },
    $route: {
      immediate: true,
      handler() {
        this.fetch()
      },
    },
    restaurant() {
      this.handleDependencies()
    },
  },
  created() {
    this.handleDependencies()
  },
  methods: {
    ...mapActions({
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
      setIsInfo: 'main/setIsInfo',
      setNavHistory: 'main/setNavHistory',
      updateMenu: 'menuManagement/updateMenu',
      createMenuItem: 'menuManagement/createMenuItem',
      getZones: 'floorPlan/getZones',
      setZones: 'floorPlan/setZones',
      createZone: 'floorPlan/createZone',
      updateZone: 'floorPlan/updateZone',
      getEvents: 'floorPlan/getEvents',
      setEvents: 'floorPlan/setEvents',
      updateEvent: 'floorPlan/updateEvent',
      getSchedules: 'schedules/getSchedules',
      setMenuItems: 'menuManagement/setMenuItems',
    }),

    onClickAddToZoneEvent() {
      this.isZoneEventTreeselect = true
    },
    handleMenuInZones(value) {
      const valueFiltered = value.filter(i => i.typeTmp === 'zone')

      let addToList = []
      let removeFromList = []

      this.zones.forEach(i => {
        const isBefore = !!i.menus.find(j => j.id === this.$route.params.id)
        const isAfter = !!valueFiltered.find(j => j.id === i.id)

        if (!isBefore && isAfter) {
          addToList = [...addToList, i]
        }
        if (isBefore && !isAfter) {
          removeFromList = [...removeFromList, i]
        }
      })

      addToList.forEach(i => this.addToZone(i))
      removeFromList.forEach(i => this.removeFromZone(i))
    },
    handleMenuInEvents(value) {
      const valueFiltered = value.filter(i => i.typeTmp === 'event')

      let addTo = []
      let removeFrom = []

      this.events.forEach(i => {
        const isBefore = !!i.menus.find(j => j.id === this.$route.params.id)
        const isAfter = !!valueFiltered.find(j => j.id === i.id)

        if (!isBefore && isAfter) {
          addTo = [...addTo, i]
        }
        if (isBefore && !isAfter) {
          removeFrom = [...removeFrom, i]
        }
      })

      addTo.forEach(i => this.addToEvent(i))
      removeFrom.forEach(i => this.removeFromEvent(i))
    },
    onCloseZoneEventTreeselect(value) {
      this.handleMenuInZones(value)
      this.handleMenuInEvents(value)

      this.isZoneEventTreeselect = false
    },

    async setupCategoriesTranslations(getCategoryTranslationsResponses) {
      const menuCategoriesTranslations = getCategoryTranslationsResponses.map((i, index) => ({
        id: this.menuCategories[index].id,
        translations: i.data.getCategoryTranslations,
      }))

      const createCategoryTranslationResponses = await Promise.all(
        menuCategoriesTranslations.map(i => Promise.all(this.restaurant.translationLanguages
          .filter(j => !i.translations.find(k => k.language === j))
          .map(j => this.createCategoryTranslation(
            i.id,
            j,
            null,
            this.menuCategories.find(k => k.id === i.id).content
              .filter(k => k.type === 'Text')
              .map(k => ({ id: k.id, type: k.type, value: null })),
          )))),
      )

      this.menuCategoriesTranslations = menuCategoriesTranslations.map((i, index) => ({
        ...i,
        // eslint-disable-next-line no-undef
        translations: structuredClone([
          ...i.translations,
          ...createCategoryTranslationResponses[index].map(j => j.data.createCategoryTranslation),
        ]).sort((a, b) => a.language.localeCompare(b.language)),
      }))
    },
    onUpdateCategoryTranslations(translations, categoryId) {
      this.menuCategoriesTranslations = this.menuCategoriesTranslations
        .map(i => (i.id === categoryId ? { ...i, translations } : i))
    },

    handleDependencies() {
      if (!this.restaurant) {
        return
      }

      let getActions = []
      if (!this.isZonesFetched) {
        getActions = [...getActions, 'getZones']
      }
      if (!this.isEventsFetched) {
        getActions = [...getActions, 'getEvents']
      }
      if (!this.isSchedulesFetched) {
        getActions = [...getActions, 'getSchedules']
      }

      getActions.map(getAction => this[getAction]())
    },
    openPreviewModal() {
      this.isModalPreview = true
    },
    initGalleries() {
      this.$nextTick(() => {
        const els = document.getElementsByClassName('lightgallery')
        els.forEach(el => {
          window.lightGallery(el, {
            download: false,
          })
        })
      })
    },
    async setTranslateMode(event) {
      await this.validateContent(event ? 'voContent' : 'voContentTranslation', true)
      if (!this.isContentValid) {
        return
      }

      this.isTranslateMode = event
      this.$emit('setSidebarState', !event)

      if (!event) {
        this.initGalleries()
      }
    },
    setAttachments() {
      this.inZones = this.zones.filter(i => !!i.menus.find(j => j.id === this.$route.params.id))
      this.inEvents = this.events.filter(i => !!i.menus.find(j => j.id === this.$route.params.id))
    },
    openModalZoneEventAttach() {
      this.zonesIdToAttach = []
      this.eventsIdToAttach = []
      this.isFutureEvent = false

      this.isModalZoneEventAttach = true
    },

    async fetch() {
      if (!this.isMenusFetched || !this.isZonesFetched || !this.isEventsFetched || !this.isSchedulesFetched) {
        return
      }

      this.isFetching = true

      const history = ['menu', this.$route.params.id]
      this.$emit('setLastOpened', history)
      this.setNavHistory(history)

      const menu = this.menus.find(i => i.id === this.$route.params.id)
      if (!menu) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('Not found'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        await this.$router.push({ name: 'menu-management' })
        return
      }

      const [
        getMenuTranslationsResponse,
        ...getCategoryTranslationsResponses
      ] = await Promise.all([
        this.$apollo.query({
          query: GET_MENU_TRANSLATIONS,
          variables: {
            getMenuTranslationsId: menu.id,
          },
        }),
        ...menu.categories.map(i => this.getCategoryTranslations(i.id)),
      ])

      this.isPublished = menu.isPublished
      // this.isEnabled = menu.isEnabled
      this.isVisible = menu.isVisible
      this.name = menu.name

      // eslint-disable-next-line no-undef
      this.menuCategories = structuredClone(menu.categories)

      // eslint-disable-next-line no-undef
      this.content = structuredClone(menu.content)

      await this.setTranslations(getMenuTranslationsResponse.data.getMenuTranslations)
      await this.setupCategoriesTranslations(getCategoryTranslationsResponses)

      this.initGalleries()

      this.setAttachments()

      if (this.$refs.name && this.$route.params.isFocusOnName) {
        this.$refs.name.focus()
      }

      this.isFetching = false
    },

    async publish() {
      if (this.isSubscriptionInactive) {
        return
      }

      this.isPublishing = true

      if (!this.zones.length) {
        const zone = await this.createZone('All Restaurant')

        // eslint-disable-next-line no-undef
        this.setZones([...this.zones, structuredClone(zone)])
      }

      if (this.restaurant.eventsSettings.enabled) {
        this.openModalZoneEventAttach()
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.zones.length === 1) {
          this.zonesIdToAttach = [this.zones[this.zones.length - 1].id]
          await this.attachAndPublish()
        } else {
          this.openModalZoneEventAttach()
        }
      }

      this.isPublishing = false
    },
    async attachAndPublish() {
      this.isAttachingAndPublishing = true

      await Promise.all([
        ...this.zonesIdToAttach.map(i => this.updateZone([
          i,
          'menus',
          [...this.zones.find(j => j.id === i).menus.map(j => j.id), this.$route.params.id],
        ])),
        ...this.eventsIdToAttach.map(i => this.updateEvent([
          i,
          'menus',
          [...this.events.find(j => j.id === i).menus.map(j => j.id), this.$route.params.id],
        ])),
      ])
      await Promise.all([this.getZones(), this.getEvents()])
      this.setAttachments()

      await Promise.all(this.patchBase('isPublished', true))
      this.isPublished = true
      this.updateMenu([this.$route.params.id, 'isPublished', this.isPublished])

      this.isModalZoneEventAttach = false
      this.isAttachingAndPublishing = false
    },
    addToZone(zone) {
      this.updateZone([
        zone.id,
        'menus',
        [...zone.menus.map(i => i.id), this.$route.params.id],
      ])

      this.setZones(
        this.zones.map(i => (i.id === zone.id
          ? { ...i, menus: [...i.menus, { id: this.$route.params.id, name: this.name }] }
          : i
        )),
      )

      this.inZones = this.zones.filter(i => !!i.menus.find(j => j.id === this.$route.params.id))
    },
    removeFromZone(zone) {
      this.updateZone([
        zone.id,
        'menus',
        zone.menus.filter(i => i.id !== this.$route.params.id).map(i => i.id),
      ])

      this.setZones(
        this.zones.map(i => (i.id === zone.id
          ? { ...i, menus: i.menus.filter(j => j.id !== this.$route.params.id) }
          : i
        )),
      )

      this.inZones = this.inZones.filter(i => i.id !== zone.id)
    },
    onClickRemoveFromZone(zone) {
      this.removeFromZone(zone)
    },
    addToEvent(event) {
      this.updateEvent([
        event.id,
        'menus',
        [...event.menus.map(i => i.id), this.$route.params.id],
      ])

      this.setEvents(
        this.events.map(i => (i.id === event.id
          ? { ...i, menus: [...i.menus, { id: this.$route.params.id, name: this.name }] }
          : i
        )),
      )

      this.inEvents = this.events.filter(i => !!i.menus.find(j => j.id === this.$route.params.id))
    },
    removeFromEvent(event) {
      this.updateEvent([
        event.id,
        'menus',
        event.menus.filter(i => i.id !== this.$route.params.id).map(i => i.id),
      ])

      this.setEvents(
        this.events.map(i => (i.id === event.id
          ? { ...i, menus: i.menus.filter(j => j.id !== this.$route.params.id) }
          : i
        )),
      )

      this.inEvents = this.inEvents.filter(i => i.id !== event.id)
    },
    onClickRemoveFromEvent(event) {
      this.removeFromEvent(event)
    },

    patchBase(name, value) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_MENU,
          variables: {
            updateMenuId: this.$route.params.id,
            [name]: value,
          },
        }),
      ]
    },

    patchName() {
      return this.patchBase('name', this.name)
    },
    async onBlurName(refName) {
      if (this.name === this.menus.find(i => i.id === this.$route.params.id).name) {
        return
      }

      const isValid = await this.$refs[refName].validate()
      if (!isValid) {
        return
      }

      this.updateMenu([this.$route.params.id, 'name', this.name])

      this.requests.push('patchName')
      this.requests.push(() => [
        this.getMenus(),
        this.getMenuItems(),
        this.getZones(),
        this.getEvents(),
      ])
    },

    patchIsVisible() {
      return this.patchBase('isVisible', this.isVisible)
    },
    onClickIsVisible(value) {
      this.isVisible = value

      this.updateMenu([this.$route.params.id, 'isVisible', this.isVisible])

      this.requests.push('patchIsVisible')
    },

    async validateContent(refName, showNotification) {
      this.isContentValid = await this.$refs[refName].validate()
      if (!this.isContentValid && showNotification) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: this.$t('notificationErrorValidateData'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    patchContent() {
      this.updateMenu([this.$route.params.id, 'content', this.content])

      return this.patchBase('content', this.content)
    },
    async patchContentIfValid(refName, showNotification) {
      await this.validateContent(refName, showNotification)
      if (this.isContentValid) {
        const patchContent = this.patchContent.bind(this)
        this.requests.push(patchContent)
      }
    },
    async patchCategoryContentIfValid(refName, showNotification, categoryId) {
      await this.validateContent(refName, showNotification)
      if (this.isContentValid) {
        this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

        this.requests.push(() => this.updateCategory(
          categoryId,
          { content: this.menuCategories.find(i => i.id === categoryId).content },
        ))
      }
    },
    async onBlurContentText(refName) {
      await this.patchContentIfValid(refName, true)
    },
    async onBlurCategoryContentText(refName, categoryId) {
      await this.patchCategoryContentIfValid(refName, true, categoryId)
    },
    onEndDragContent(event) {
      if (event.oldIndex === event.newIndex) {
        return
      }

      let toPatch = ['patchContent']

      if (this.content.filter(i => i.type === 'Text').length) {
        this.translations = this.translations.map(i => ({
          ...i,
          content: this.content
            .filter(j => j.type === 'Text')
            .map(j => ({
              id: j.id,
              type: 'Text',
              value: i.content.find(k => k.id === j.id).value,
            })),
        }))
        toPatch = [...toPatch, 'patchTranslations']
      }

      this.requests.push(() => toPatch.flatMap(i => this[i]()))
    },
    async onClickDeleteContent(item) {
      if (item.type === 'Image') {
        this.content = this.content.filter(i => i.id !== item.id)
        this.requests.push('patchContent')
      }

      if (item.type === 'Text') {
        this.content = this.content.filter(i => i.id !== item.id)
        this.translations = this.translations.map(i => ({ ...i, content: i.content.filter(j => j.id !== item.id) }))
        this.requests.push(() => [...this.patchContent(), ...this.patchTranslations()])
      }

      if (item.type === 'Category') {
        this.content = this.content.filter(i => i.id !== item.id)
        this.updateMenu([this.$route.params.id, 'content', this.content])

        this.menuCategories = this.menuCategories.filter(i => i.id !== item.id)
        this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

        this.requests.push(
          () => this.patchBase('categories', this.menuCategories.map(i => i.id)),
          () => this.deleteCategory(item.id),
        )
      }
    },
    onClickDeleteCategoryContent(contentType, categoryId, contentId) {
      if (contentType === 'Image') {
        this.menuCategories = this.menuCategories.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              content: i.content.filter(j => j.id !== contentId),
            }
          }
          return i
        })

        this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

        this.requests.push(() => this.updateCategory(
          categoryId,
          { content: this.menuCategories.find(i => i.id === categoryId).content },
        ))
      }
      if (contentType === 'Text') {
        this.menuCategories = this.menuCategories.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              content: i.content.filter(j => j.id !== contentId),
            }
          }
          return i
        })
        this.menuCategoriesTranslations = this.menuCategoriesTranslations.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              translations: i.translations.map(j => ({
                ...j,
                content: j.content.filter(k => k.id !== contentId),
              })),
            }
          }
          return i
        })

        this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

        this.requests.push(() => [
          ...this.updateCategory(
            categoryId,
            { content: this.menuCategories.find(i => i.id === categoryId).content },
          ),
          ...this.menuCategoriesTranslations.find(i => i.id === categoryId).translations
            .map(i => this.updatedCategoryTranslation(categoryId, i)),
        ])
      }
    },
    async onDeleteCategoryContentOffer(offer, categoryId) {
      this.menuCategories = this.menuCategories.map(i => {
        if (i.id === categoryId) {
          return {
            ...i,
            items: i.items.filter(j => j.id !== offer.id),
            content: i.content.filter(j => j.id !== offer.id),
          }
        }
        return i
      })

      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      this.requests.push(
        () => this.updateCategory(
          categoryId,
          { items: this.menuCategories.find(i => i.id === categoryId).items.map(i => i.id) },
        ),
        () => this.deleteOffer(offer.id),
      )
    },

    clearAddOfferParams() {
      this.addOfferParams.mode = ''
      this.addOfferParams.categoryId = ''
      this.addOfferParams.selectedItemsIds = []
      this.addOfferParams.newItemName = ''
    },
    onClickCancelAddOffer() {
      this.clearAddOfferParams()
    },
    onClickStartAddOffer(mode, categoryId) {
      this.clearAddOfferParams()
      this.addOfferParams.mode = mode
      this.addOfferParams.categoryId = categoryId

      if (mode === 'select') {
        this.$nextTick(() => {
          this.$refs[`selectedItemsIds_${categoryId}`][0].searchEl.focus()
        })
      }
      if (mode === 'new') {
        this.$nextTick(() => {
          this.$refs[`newItemName_${categoryId}`][0].focus()
        })
      }
    },
    async onClickCreateOffers() {
      this.isContentLoader = true

      const createOfferResponses = await Promise.all(
        this.addOfferParams.selectedItemsIds.flatMap(i => this.createOffer(i)),
      )

      const updateCategoryResponse = await this.updateCategory(
        this.addOfferParams.categoryId,
        {
          items: [
            ...this.menuCategories.find(i => i.id === this.addOfferParams.categoryId).items.map(i => i.id),
            ...createOfferResponses.map(i => i.data.createOffer.id),
          ],
        },
      )[0]

      const menuCategories = this.menuCategories.map(i => (
        // eslint-disable-next-line no-undef
        i.id === this.addOfferParams.categoryId ? structuredClone(updateCategoryResponse.data.updateCategory) : i
      ))

      // eslint-disable-next-line no-undef
      this.menuCategories = structuredClone(menuCategories)
      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      this.clearAddOfferParams()

      this.isContentLoader = false
    },
    async onClickCreateOffer() {
      this.isContentLoader = true

      const menuItem = await this.createMenuItem([
        this.addOfferParams.newItemName,
        'MenuItem',
        this.$t('Also consider ordering'),
      ])
      await this.setMenuItems([...this.menuItems, menuItem])

      const createOfferResponse = await this.createOffer(menuItem.id)[0]

      const updateCategoryResponse = await this.updateCategory(
        this.addOfferParams.categoryId,
        {
          items: [
            ...this.menuCategories.find(i => i.id === this.addOfferParams.categoryId).items.map(i => i.id),
            createOfferResponse.data.createOffer.id,
          ],
        },
      )[0]

      const menuCategories = this.menuCategories.map(i => (
        // eslint-disable-next-line no-undef
        i.id === this.addOfferParams.categoryId ? structuredClone(updateCategoryResponse.data.updateCategory) : i
      ))

      // eslint-disable-next-line no-undef
      this.menuCategories = structuredClone(menuCategories)
      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      this.clearAddOfferParams()

      this.isContentLoader = false
    },

    async onClickAddCategory() {
      this.isContentLoader = true

      let categoryName = this.$t('categoryDefaultName')
      const categoryNameNumber = this.menuCategories.length + 1
      if (categoryNameNumber > 1) {
        categoryName += ` ${categoryNameNumber}`
      }
      const createCategoryResponse = await this.createCategory(categoryName)[0]
      // eslint-disable-next-line no-undef
      const category = structuredClone(createCategoryResponse.data.createCategory)

      const [
        updateMenuResponse,
        ...createCategoryTranslationResponses
      ] = await Promise.all([
        ...this.patchBase('categories', [...this.menuCategories.map(i => i.id), category.id]),
        ...this.restaurant.translationLanguages
          .map(i => this.createCategoryTranslation(category.id, i, null, [])),
      ])

      // eslint-disable-next-line no-undef
      this.menuCategories = structuredClone(updateMenuResponse.data.updateMenu.categories)
      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      this.menuCategoriesTranslations = [
        ...this.menuCategoriesTranslations,
        {
          id: category.id,
          // eslint-disable-next-line no-undef
          translations: structuredClone(
            createCategoryTranslationResponses.map(i => i.data.createCategoryTranslation),
          ).sort((a, b) => a.language.localeCompare(b.language)),
        },
      ]

      // eslint-disable-next-line no-undef
      this.content = structuredClone(updateMenuResponse.data.updateMenu.content)
      this.updateMenu([this.$route.params.id, 'content', this.content])

      setTimeout(() => {
        this.$refs[`cardContentArrow_${category.id}`][0].click()
      })

      this.isContentLoader = false
    },
    async onClickAddToContentText() {
      this.isContentLoader = true

      const item = { id: uuidv4(), type: 'Text', value: '' }

      this.content = [...this.content, { ...item }]
      this.translations = this.translations.map(i => ({ ...i, content: [...i.content, { ...item }] }))

      await Promise.all([...this.patchContent(), ...this.patchTranslations()])

      this.isContentLoader = false
    },
    async onClickAddToCategoryContentText(categoryId) {
      this.isContentLoader = true

      const item = { id: uuidv4(), type: 'Text', value: '' }

      this.menuCategories = this.menuCategories.map(i => {
        if (i.id === categoryId) {
          return {
            ...i,
            content: [...i.content, { ...item }],
          }
        }
        return i
      })
      this.menuCategoriesTranslations = this.menuCategoriesTranslations.map(i => {
        if (i.id === categoryId) {
          return {
            ...i,
            translations: i.translations.map(j => ({ ...j, content: [...j.content, { ...item }] })),
          }
        }
        return i
      })

      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      await Promise.all([
        ...this.updateCategory(
          categoryId,
          { content: this.menuCategories.find(i => i.id === categoryId).content },
        ),
        ...this.menuCategoriesTranslations.find(i => i.id === categoryId).translations
          .map(i => this.updatedCategoryTranslation(categoryId, i)),
      ])

      this.isContentLoader = false
    },
    onPostingImage() {
      this.isContentLoader = true
    },
    async onDonePostingImageForMenuContent(event) {
      this.content = [...this.content, { id: event.id, type: 'Image', value: event.linkName }]

      await Promise.all(this.patchContent())

      this.initGalleries()

      this.isContentLoader = false
    },
    async onDonePostingImageForCategoryContent(event, categoryId) {
      this.menuCategories = this.menuCategories.map(i => {
        if (i.id === categoryId) {
          return {
            ...i,
            content: [
              ...i.content,
              { id: event.id, type: 'Image', value: event.linkName },
            ],
          }
        }
        return i
      })

      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      await this.updateCategory(
        categoryId,
        { content: this.menuCategories.find(i => i.id === categoryId).content },
      )[0]

      this.initGalleries()

      this.isContentLoader = false
    },

    onPatchCategoryName(nameValue, categoryId) {
      this.menuCategories = this.menuCategories.map(i => (i.id === categoryId ? { ...i, name: nameValue } : i))

      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      this.requests.push(
        () => this.updateCategory(categoryId, { name: nameValue }),
        () => [this.getMenus()],
      )
    },
    onChangeDraggableCategoryContent(event, categoryId) {
      let updateCategoryVariables = {
        content: this.menuCategories.find(i => i.id === categoryId).content,
      }

      if (event.added && event.added.element.type === 'Offer') {
        const categoryOfAddedOffer = this.menuCategories.find(i => i.items.find(j => j.id === event.added.element.id))
        const itemOfAddedOffer = categoryOfAddedOffer.items.find(i => i.id === event.added.element.id)
        this.menuCategories = this.menuCategories.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              // eslint-disable-next-line no-undef
              items: [...i.items, structuredClone(itemOfAddedOffer)],
            }
          }
          return i
        })
      }
      if (event.removed && event.removed.element.type === 'Offer') {
        this.menuCategories = this.menuCategories.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              items: i.items.filter(j => j.id !== event.removed.element.id),
            }
          }
          return i
        })
      }
      if (
        (event.added && event.added.element.type === 'Offer')
        || (event.removed && event.removed.element.type === 'Offer')
      ) {
        updateCategoryVariables = {
          ...updateCategoryVariables,
          items: this.menuCategories.find(i => i.id === categoryId).items.map(i => i.id),
        }
      }

      let updateTranslations = false
      if (
        (event.added && event.added.element.type === 'Text')
        || (event.removed && event.removed.element.type === 'Text')
        || (event.moved && event.moved.element.type === 'Text')
      ) {
        updateTranslations = true
        this.menuCategoriesTranslations = this.menuCategoriesTranslations.map(i => {
          if (i.id === categoryId) {
            return {
              ...i,
              translations: i.translations.map(j => ({
                ...j,
                content: this.menuCategories.find(k => k.id === categoryId).content
                  .filter(k => k.type === 'Text')
                  .map(k => ({
                    ...k,
                    // eslint-disable-next-line no-nested-ternary
                    value: j.content.find(m => m.id === k.id)
                      ? j.content.find(m => m.id === k.id).value
                      : this.menuCategoriesTranslations
                        .find(m => m.translations.find(n => n.content.find(p => p.id === k.id)))
                        ? this.menuCategoriesTranslations
                          .find(m => m.translations.find(n => n.content.find(p => p.id === k.id))).translations
                          .find(n => n.content.find(p => p.id === k.id)).content
                          .find(p => p.id === k.id).value
                        : '',
                  })),
              })),
            }
          }
          return i
        })
      }

      this.updateMenu([this.$route.params.id, 'categories', this.menuCategories])

      this.requests.push(() => [
        ...this.updateCategory(categoryId, updateCategoryVariables),
        ...(updateTranslations
          ? this.menuCategoriesTranslations.find(i => i.id === categoryId).translations
            .map(i => this.updatedCategoryTranslation(categoryId, i))
          : []
        ),
      ])
    },

    onUpdateOfferInMenuCategories(offer, categoryId) {
      this.menuCategories = this.menuCategories
        .map(i => (i.id === categoryId
          // eslint-disable-next-line no-undef
          ? { ...i, items: i.items.map(j => (j.id === offer.id ? structuredClone(offer) : j)) }
          : i
        ))
    },

    getTranslationPayload(i) {
      return {
        language: i.language,

        name: i.name || null,
        content: i.content.map(j => ({ ...j, value: j.value || null })),
      }
    },
    getTranslationPayloadClear(language) {
      return {
        language,

        name: null,
        content: this.content
          .filter(j => j.type === 'Text')
          .map(j => ({ id: j.id, type: j.type, value: null })),
      }
    },
    onClearTranslation() {
      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: null,
            content: i.content.map(j => ({ ...j, value: null })),
          }
        }
        return i
      })

      this.menuCategoriesTranslations = this.menuCategoriesTranslations.map(i => ({
        ...i,
        translations: i.translations.map(j => {
          if (j.language === this.translationActive) {
            return {
              ...j,
              name: '',
              content: j.content.map(k => ({ ...k, value: '' })),
            }
          }
          return j
        }),
      }))

      this.patchTranslationActiveAndCategoriesTranslationActive()
    },
    patchTranslationActiveAndCategoriesTranslationActive() {
      this.requests.push(
        () => [
          this.patchTranslation.bind(this, this.translations.find(i => i.language === this.translationActive))(),
          ...this.menuCategoriesTranslations
            .map(i => this.updatedCategoryTranslation(
              i.id,
              i.translations.find(j => j.language === this.translationActive),
            )),
        ],
      )
    },

    async translateAll() {
      const initialRouteName = this.$route.name
      const initialRouteId = this.$route.params.id
      const initialTranslationActive = this.translationActive

      const response = await this.getGoogleTranslation([
        this.name,
        ...this.content.filter(i => i.type === 'Text').map(i => i.value),
        ...this.menuCategories.flatMap(i => [
          i.name,
          ...i.content.filter(j => j.type === 'Text').map(j => j.value),
        ]),
      ])

      if (
        !response
        || initialRouteName !== this.$route.name
        || initialRouteId !== this.$route.params.id
        || initialTranslationActive !== this.translationActive
      ) {
        return
      }

      let index = 0 // index of last element in main data (name)
      this.translations = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: response[index],
            content: i.content.map(j => {
              index += 1
              return { ...j, value: response[index] }
            }),
          }
        }
        return i
      })

      this.menuCategoriesTranslations = this.menuCategoriesTranslations.map(i => ({
        ...i,
        translations: i.translations.map(j => {
          if (j.language === this.translationActive) {
            index += 1
            return {
              ...j,
              name: response[index],
              content: j.content.map(k => {
                index += 1
                return { ...k, value: response[index] }
              }),
            }
          }
          return j
        }),
      }))

      this.patchTranslationActiveAndCategoriesTranslationActive()
    },
    async onClickTranslateField(path) {
      await this.translateField(path)
      this.patchTranslationActive()
    },
    async onClickTranslateContent(contentItemId) {
      await this.translateContent(contentItemId)
      this.patchTranslationActive()
    },

    async onClickDuplicate() {
      this.isDuplicating = true

      try {
        const response = await this.$apollo.mutate({
          mutation: DUPLICATE_MENU,
          variables: {
            duplicateMenuId: this.$route.params.id,
          },
        })

        await Promise.all([
          this.getMenus(),
          this.getMenuItems(),
          this.getZones(),
          this.getEvents(),
          this.getSchedules(),
        ])

        await this.$router.push({
          name: 'menu',
          params: {
            id: response.data.duplicateMenu.id,
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isDuplicating = false
      }
    },
    async onDelete() {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_MENU,
          variables: {
            deleteMenuId: this.$route.params.id,
          },
        })

        await Promise.all([
          this.getMenus(),
          this.getMenuItems(),
          this.getZones(),
          this.getEvents(),
          this.getSchedules(),
        ])

        await this.$router.push({ name: 'menu-management' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessDeleted'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
