<template>
  <div style="margin-bottom: -2rem">
    <translate
      v-if="isTranslateMode"
      :translations="translations"
      :translation-active="translationActive"
      :title="name"
      @setTranslationActive="translationActive = $event"
      @setTranslateMode="setTranslateMode"
      @translate="translateAll"
      @clearTranslation="onClearTranslation"
    >
      <template v-slot:default>
        <b-card>
          <b-form-group
            :label="$t('Menu Name')"
            class="mb-0"
          >
            <validation-observer ref="voNameTranslation">
              <validation-provider
                #default="{ errors }"
                name="menu_name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :placeholder="$t('Menu Name')"
                  :state="errors.length ? false : null"
                  @blur="onBlurName('voNameTranslation')"
                />
              </validation-provider>
            </validation-observer>
          </b-form-group>
        </b-card>
        <validation-observer ref="voContentTranslation">
          <b-card
            v-if="content.filter(i => i.type === 'Category').length"
            :title="$t('menuCategoriesTitle')"
          >
            <div
              v-for="(contentItem, contentIndex) in content.filter(i => i.type === 'Category')"
              :key="contentItem.id"
              :class="['mt-1', { 'mt-0': contentIndex === 0 }]"
            >
              <div class="card-inner">
                <b-card class="mb-0">
                  <b-form-group
                    :label="$t('Category Name')"
                    class="mb-0"
                  >
                    <category-name
                      :category-id="contentItem.id"
                      :category-name="menuCategories.find(i => i.id === contentItem.id).name"
                      @patchCategoryName="onPatchCategoryName($event, contentItem.id)"
                    />
                  </b-form-group>
                  <div
                    v-if="menuCategories.find(i => i.id === contentItem.id).content.filter(i => i.type === 'Text').length"
                    class="mt-2 pt-50"
                  >
                    <b-form-group
                      :label="$t('Category Contents')"
                      class="mb-0"
                    >
                      <div
                        v-for="(menuCategoryText, menuCategoryTextIndex) in menuCategories.find(i => i.id === contentItem.id).content.filter(i => i.type === 'Text')"
                        :key="menuCategoryText.id"
                        :class="[{ 'mb-1': menuCategoryTextIndex + 1 !== menuCategories.find(i => i.id === contentItem.id).content.filter(i => i.type === 'Text').length }]"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="add_text"
                          :vid="menuCategoryText.id"
                          :rules="`max:${textLimit}`"
                        >
                          <b-form-textarea
                            v-model="menuCategoryText.value"
                            rows="8"
                            no-resize
                            :disabled="isContentLoader"
                            :placeholder="$t('Text')"
                            :state="errors.length ? false : null"
                            @blur="onBlurContentText('voContentTranslation')"
                          />
                        </validation-provider>
                      </div>
                    </b-form-group>
                  </div>
                </b-card>
              </div>
            </div>
          </b-card>
          <b-card
            v-if="content.filter(i => i.type === 'Text').length"
            :title="$t('menuContentTitle')"
          >
            <div
              v-for="(contentItem, contentIndex) in content.filter(i => i.type === 'Text')"
              :key="contentItem.id"
              :class="['mt-1', { 'mt-0': contentIndex === 0 }]"
            >
              <validation-provider
                #default="{ errors }"
                name="add_text"
                :vid="contentItem.id"
                :rules="`max:${textLimit}`"
              >
                <b-form-textarea
                  v-model="contentItem.value"
                  rows="8"
                  no-resize
                  :disabled="isContentLoader"
                  :placeholder="$t('Text')"
                  :state="errors.length ? false : null"
                  @blur="onBlurContentText('voContentTranslation')"
                />
              </validation-provider>
            </div>
          </b-card>
        </validation-observer>
      </template>
      <template v-slot:translation="{ translation }">
        <b-card>
          <b-form-group
            :label="$t('Menu Name')"
            class="mb-0"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="translation.name"
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Menu Name')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateField('name')"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
        <b-card
          v-if="content.filter(i => i.type === 'Category').length"
          :title="$t('menuCategoriesTitle')"
        >
          <div
            v-for="(contentItem, contentIndex) in content.filter(i => i.type === 'Category')"
            :key="contentItem.id"
            :class="['mt-1', { 'mt-0': contentIndex === 0 }]"
          >
            <category-translation
              :category-data="menuCategories.find(i => i.id === contentItem.id)"
              :translations="menuCategoriesTranslations.find(i => i.id === contentItem.id).translations"
              :translation-active="translationActive"
              @updateTranslations="onUpdateCategoryTranslations($event, contentItem.id)"
            />
          </div>
        </b-card>
        <b-card
          v-if="translation.content.filter(i => i.type === 'Text').length"
          :title="$t('menuContentTitle')"
        >
          <div
            v-for="(contentItem, contentIndex) in translation.content.filter(i => i.type === 'Text')"
            :key="contentItem.id"
            :class="['mt-1', { 'mt-0': contentIndex === 0 }]"
          >
            <b-input-group class="input-group-merge">
              <b-form-textarea
                v-model="contentItem.value"
                rows="8"
                no-resize
                :disabled="isSubscriptionInactiveOrBasic"
                :placeholder="$t('Text')"
                @blur="patchTranslationActive"
              />
              <b-input-group-append
                v-if="isSubscriptionPremium"
                is-text
              >
                <icon-translate
                  v-b-tooltip.hover.top="$t('Auto Translate')"
                  class="size-18"
                  role="button"
                  @click.native="onClickTranslateContent(contentItem.id)"
                />
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-card>
      </template>
    </translate>
    <template v-else>
      <b-card v-if="!isPublished && !isFetching">
        <b-row class="d-flex align-items-center">
          <b-col>{{ $t('notificationNotPublished') }}</b-col>
          <b-col cols="auto">
            <b-button
              id="publish"
              variant="outline-primary"
              :disabled="isPublishing"
              @click="publish"
            >
              <b-spinner
                v-if="isPublishing"
                variant="primary"
                small
              />
              <template v-else>
                {{ $t('Publish menu') }}
              </template>
            </b-button>
            <b-tooltip
              v-if="isPublishTooltip"
              target="publish"
            >
              <div class="pt-50 pb-50">
                <span>{{ $t('To publish your menu you need to become a subscriber') }}</span>
                <b-button
                  block
                  size="sm"
                  class="mt-50"
                  variant="primary"
                  @click="$router.push({ name: 'billing' })"
                >
                  {{ $t('Start Free Trial') }}
                </b-button>
              </div>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-card>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card>
          <b-row class="d-flex">
            <b-col>
              <validation-observer ref="voName">
                <validation-provider
                  #default="{ errors, failedRules }"
                  name="menu_name"
                  rules="required"
                >
                  <b-form-input
                    ref="name"
                    v-model="name"
                    :placeholder="$t('Menu Name')"
                    class="input-h input-h4"
                    :state="errors.length ? false : null"
                    @blur="onBlurName('voName')"
                  />
                  <small class="text-danger">
                    <template v-if="failedRules.required">{{ $t('validationErrorRequiredMenuName') }}</template>
                    <template v-else>{{ errors[0] }}</template>
                  </small>
                </validation-provider>
              </validation-observer>
            </b-col>
            <b-col
              cols="auto"
              style="padding-top: 9px"
            >
              <div class="d-flex">
                <feather-icon
                  v-if="isPublished"
                  v-b-tooltip.hover.top="!isVisible ? $t('tooltipShowInMenusList') : $t('tooltipHideInMenusList')"
                  class="mr-2"
                  :icon="isVisible ? 'EyeIcon' : 'EyeOffIcon'"
                  role="button"
                  size="20"
                  @click="onClickIsVisible(!isVisible)"
                />
                <icon-preview
                  v-b-tooltip.hover.top="$t('Preview')"
                  role="button"
                  class="size-20 mr-2"
                  @click.native="openPreviewModal"
                />
                <!--<icon-stop-list
                  v-b-tooltip.hover.top="$t('Temporary Unavailable')"
                  :class="['mr-2 size-20', {'fill-danger': !isEnabled}]"
                  role="button"
                  @click.native="isEnabled = !isEnabled"
                />-->
                <icon-translate
                  v-b-tooltip.hover.top="$t('Translate')"
                  role="button"
                  class="size-20 mr-2"
                  @click.native="setTranslateMode(true)"
                />
                <b-spinner
                  v-if="isDuplicating"
                  variant="primary"
                  class="spinner-20 mr-2"
                />
                <feather-icon
                  v-if="isDuplicate"
                  v-b-tooltip.hover.top="$t('Duplicate')"
                  class="mr-2"
                  icon="CopyIcon"
                  role="button"
                  size="20"
                  @click="onClickDuplicate"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Delete')"
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
                  role="button"
                  @click="isModalConfirmDelete = true"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-overlay>
      <b-overlay
        :show="isFetching || isContentLoader"
        spinner-variant="primary"
      >
        <b-card-actions
          :title="$t('menuContentTitle')"
          action-collapse
        >
          <template v-slot:after-title>
            <feather-icon
              icon="InfoIcon"
              role="button"
              size="20"
              style="vertical-align: top"
              class="ml-25 text-primary toggle-content"
              @click.stop="setIsInfo(['menu', 'content'])"
            />
          </template>
          <template v-slot:default>
            <b-collapse v-model="isInfo.menu.content">
              <small class="d-block mb-2">
                {{ $t('infoMenuContent') }}
              </small>
            </b-collapse>
            <div :class="[{'mb-2': content.length }]">
              <validation-observer ref="voContent">
                <draggable
                  v-model="content"
                  :disabled="!isContentValid"
                  @end="onEndDragContent"
                >
                  <div
                    v-for="(item, itemIndex) in content"
                    :key="item.id"
                    :class="[
                      'card-content__item card-content__item_border-top',
                      { 'card-content__item_border-bottom': itemIndex + 1 === content.length },
                    ]"
                  >
                    <b-row
                      :class="[
                        'ml-n2 mr-n2 pl-50 pr-50 pt-75 pb-75',
                        { 'card-content__clicked': lastClickedContentRowId === item.id },
                      ]"
                      @click="lastClickedContentRowId = item.id"
                    >
                      <b-col v-if="item.type === 'Image'">
                        <menu-content-image
                          :image="item"
                          :is-content-valid="isContentValid"
                          @delete="onClickDeleteContent(item)"
                        />
                      </b-col>
                      <b-col v-if="item.type === 'Text'">
                        <menu-content-text
                          :item="item"
                          :text-limit="textLimit"
                          :is-content-valid="isContentValid"
                          @textarea-input="item.value = $event"
                          @textarea-blur="onBlurContentText('voContent')"
                          @delete="onClickDeleteContent(item)"
                        />
                      </b-col>
                      <template v-if="item.type === 'Category'">
                        <b-col>
                          <div class="d-flex align-items-center">
                            <div
                              :ref="`cardContentArrow_${item.id}`"
                              v-b-toggle="item.id + '_content'"
                              class="card-content__arrow"
                            >
                              <feather-icon icon="ChevronDownIcon" />
                            </div>
                            <category-name
                              input-class="input-h input-h5"
                              :category-id="item.id"
                              :category-name="menuCategories.find(i => i.id === item.id).name"
                              @patchCategoryName="onPatchCategoryName($event, item.id)"
                            />
                          </div>
                        </b-col>
                        <b-col
                          cols="auto"
                          class="d-flex align-items-center"
                        >
                          <div class="card-content__transparent">
                            <b-spinner
                              v-if="deletingFromContent.find(i => i === item.id)"
                              variant="primary"
                              class="spinner-20"
                            />
                            <feather-icon
                              v-else
                              :class="[!isContentValid ? 'feather-disabled' : null]"
                              role="button"
                              size="20"
                              icon="XCircleIcon"
                              @click="onClickDeleteContent(item)"
                            />
                            <icon-move-up-down :class="['ml-1 cursor-move', { 'disabled': !isContentValid }]" />
                          </div>
                        </b-col>
                      </template>
                    </b-row>
                    <b-collapse
                      v-if="item.type === 'Category'"
                      :id="item.id + '_content'"
                      class="ml-n2 mr-n2"
                    >
                      <draggable
                        group="offers"
                        draggable=".class-for-draggable-component"
                        :disabled="!isContentValid"
                        :list="menuCategories.find(i => i.id === item.id).content"
                        @change="onChangeDraggableCategoryContent($event, item.id)"
                      >
                        <div
                          v-for="categoryContentItem in menuCategories.find(i => i.id === item.id).content"
                          :key="categoryContentItem.id"
                          :class="[
                            'class-for-draggable-component card-content__category-content pt-75 pb-75',
                            { 'card-content__clicked': lastClickedContentRowId === categoryContentItem.id },
                          ]"
                          @click="lastClickedContentRowId = categoryContentItem.id"
                        >
                          <b-col>
                            <menu-content-image
                              v-if="categoryContentItem.type === 'Image'"
                              class="pr-50"
                              style="padding-left: 3.2rem"
                              :image="categoryContentItem"
                              :is-content-valid="isContentValid"
                              @delete="onClickDeleteCategoryContent(categoryContentItem.type, item.id, categoryContentItem.id)"
                            />
                            <menu-content-text
                              v-if="categoryContentItem.type === 'Text'"
                              class="pr-50"
                              style="padding-left: 3.2rem"
                              :item="categoryContentItem"
                              :text-limit="textLimit"
                              :is-content-valid="isContentValid"
                              @textarea-input="categoryContentItem.value = $event"
                              @textarea-blur="onBlurCategoryContentText('voContent', item.id)"
                              @delete="onClickDeleteCategoryContent(categoryContentItem.type, item.id, categoryContentItem.id)"
                            />
                            <menu-content-offer
                              v-if="categoryContentItem.type === 'Offer' && menuCategories.find(i => i.id === item.id).items.find(i => i.id === categoryContentItem.id)"
                              class="pr-50 pl-50"
                              :offer="menuCategories.find(i => i.id === item.id).items.find(i => i.id === categoryContentItem.id)"
                              :is-content-valid="isContentValid"
                              @updateOfferInMenuCategories="onUpdateOfferInMenuCategories($event, item.id)"
                              @delete="onDeleteCategoryContentOffer($event, item.id)"
                            />
                          </b-col>
                        </div>
                        <div
                          slot="footer"
                          key="footer"
                          role="group"
                          class="pt-50 pb-1 pl-2 pr-2"
                        >
                          <div
                            class="d-flex align-items-center pl-1 pr-1"
                            style="min-height: 31px"
                          >
                            <b-row
                              v-if="addOfferParams.mode === 'select' && addOfferParams.categoryId === item.id"
                              class="align-items-center flex-grow-1"
                            >
                              <b-col>
                                <v-select
                                  :ref="`selectedItemsIds_${item.id}`"
                                  v-model="addOfferParams.selectedItemsIds"
                                  multiple
                                  class="select-size-sm"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  label="name"
                                  :close-on-select="false"
                                  :reduce="i => i.id"
                                  :options="itemsToAdd"
                                >
                                  <template #no-options>
                                    {{ $t('The list is empty') }}
                                  </template>
                                </v-select>
                              </b-col>
                              <b-col cols="auto">
                                <b-button
                                  size="sm"
                                  variant="primary"
                                  :disabled="!addOfferParams.selectedItemsIds.length"
                                  @click="onClickCreateOffers"
                                >
                                  {{ $t('Save') }}
                                </b-button>
                                <b-button
                                  size="sm"
                                  class="ml-1"
                                  variant="outline-primary"
                                  @click="onClickCancelAddOffer"
                                >
                                  {{ $t('Cancel') }}
                                </b-button>
                              </b-col>
                            </b-row>
                            <b-row
                              v-else-if="addOfferParams.mode === 'new' && addOfferParams.categoryId === item.id"
                              class="align-items-center flex-grow-1"
                            >
                              <b-col>
                                <b-form-input
                                  :ref="`newItemName_${item.id}`"
                                  v-model="addOfferParams.newItemName"
                                  size="sm"
                                  :placeholder="$t('menuNewItemPlaceholder')"
                                />
                              </b-col>
                              <b-col cols="auto">
                                <b-button
                                  size="sm"
                                  variant="primary"
                                  :disabled="!addOfferParams.newItemName.length"
                                  @click="onClickCreateOffer"
                                >
                                  {{ $t('Save') }}
                                </b-button>
                                <b-button
                                  size="sm"
                                  class="ml-1"
                                  variant="outline-primary"
                                  @click="onClickCancelAddOffer"
                                >
                                  {{ $t('Cancel') }}
                                </b-button>
                              </b-col>
                            </b-row>
                            <div
                              v-else
                              class="d-flex align-items-center flex-grow-1"
                            >
                              <b-dropdown
                                variant="outline-primary"
                                :text="$t('Add Item')"
                                size="sm"
                                :disabled="!isContentValid"
                              >
                                <b-dropdown-item
                                  :disabled="!itemsToAdd.length"
                                  @click="onClickStartAddOffer('select', item.id)"
                                >
                                  {{ $t('Existent') }}
                                </b-dropdown-item>
                                <b-dropdown-item @click="onClickStartAddOffer('new', item.id)">
                                  {{ $t('New') }}
                                </b-dropdown-item>
                              </b-dropdown>
                              <b-button
                                v-b-tooltip.hover.top="$t('Add Text')"
                                size="sm"
                                variant="outline-primary"
                                class="btn-icon d-flex ml-1"
                                :disabled="!isContentValid"
                                @click="onClickAddToCategoryContentText(item.id)"
                              >
                                <feather-icon icon="TypeIcon" />
                              </b-button>
                              <add-to-content-image
                                :is-btn-size-sm="true"
                                :disabled="!isContentValid"
                                :content-image-length="menuCategories.find(i => i.id === item.id).content.filter(i => i.type === 'Image').length"
                                @posting="onPostingImage"
                                @done="onDonePostingImageForCategoryContent($event, item.id)"
                              />
                            </div>
                          </div>
                        </div>
                      </draggable>
                    </b-collapse>
                  </div>
                </draggable>
              </validation-observer>
            </div>
            <div class="card-content__footer">
              <b-button
                :disabled="!isContentValid"
                variant="outline-primary"
                @click="onClickAddCategory"
              >
                {{ $t('menuPageAddCategoryButtonLabel') }}
              </b-button>
              <b-button
                v-b-tooltip.hover.top="$t('Add Text')"
                :disabled="!isContentValid"
                variant="outline-primary"
                class="btn-icon d-flex ml-1"
                @click="onClickAddToContentText"
              >
                <feather-icon icon="TypeIcon" />
              </b-button>
              <add-to-content-image
                :is-btn-size-sm="false"
                :disabled="!isContentValid"
                :content-image-length="content.filter(i => i.type === 'Image').length"
                @posting="onPostingImage"
                @done="onDonePostingImageForMenuContent"
              />
            </div>
          </template>
        </b-card-actions>
      </b-overlay>
      <b-overlay
        v-show="isCardSchedules"
        :show="isFetching"
        spinner-variant="primary"
      >
        <card-schedules
          v-if="isCardSchedules"
          info="menu"
        />
      </b-overlay>
      <b-overlay
        :show="isFetching"
        spinner-variant="primary"
      >
        <b-card
          no-body
          class="card-assignments"
        >
          <b-card-header>
            <b-row class="flex-grow-1 align-items-center">
              <b-col>
                <b-card-title>
                  {{ $t('menuCardAssignmentsTitle') }}
                </b-card-title>
              </b-col>
              <b-col
                v-if="!inZones.length && !isCardAssignmentsEvents"
                cols="auto"
              >
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="onClickAddToZoneEvent"
                >
                  {{ $t('menuCardAssignmentsAddButtonName') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body v-if="inZones.length || isCardAssignmentsEvents || isZoneEventTreeselect">
            <b-row v-if="inZones.length || isCardAssignmentsEvents">
              <b-col v-if="inZones.length">
                <div class="font-weight-bold">
                  {{ $t('menuCardAssignmentsLabelZones') }}
                </div>
                <div :class="['ml-n50', { 'mr-n50': !isCardAssignmentsEvents }]">
                  <b-row
                    v-for="inZone in inZones"
                    :key="inZone.id"
                    :class="[
                      'align-items-center mt-50 pt-50 pb-50 card-assignments__row pl-50',
                      { 'pr-50': !isCardAssignmentsEvents },
                    ]"
                  >
                    <b-col class="card-assignments__col">
                      <b-link :to="{ name: 'zone', params: { id: inZone.id } }">
                        {{ inZone.name }}
                      </b-link>
                    </b-col>
                    <b-col
                      cols="auto"
                      class="d-flex card-assignments__col card-assignments__col_remove"
                    >
                      <feather-icon
                        size="20"
                        role="button"
                        icon="XCircleIcon"
                        @click="onClickRemoveFromZone(inZone)"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col v-if="isCardAssignmentsEvents">
                <div class="font-weight-bold">
                  {{ $t('menuCardAssignmentsLabelEvents') }}
                </div>
                <div :class="['mr-n50', { 'ml-n50': !inZones.length }]">
                  <b-row
                    v-for="inEvent in inEvents"
                    :key="inEvent.id"
                    :class="[
                      'align-items-center mt-50 pt-50 pb-50 card-assignments__row pr-50',
                      { 'pl-50': !inZones.length },
                    ]"
                  >
                    <b-col class="card-assignments__col">
                      <b-link :to="{ name: 'event', params: { id: inEvent.id } }">
                        {{ inEvent.name }}
                      </b-link>
                    </b-col>
                    <b-col
                      cols="auto"
                      class="d-flex card-assignments__col card-assignments__col_remove"
                    >
                      <feather-icon
                        size="20"
                        role="button"
                        icon="XCircleIcon"
                        @click="onClickRemoveFromEvent(inEvent)"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <div :class="[{ 'mt-2': inZones.length || isCardAssignmentsEvents }]">
              <treeselect
                v-if="isZoneEventTreeselect"
                :value="[...inZones, ...inEvents]"
                :multiple="true"
                :disable-branch-nodes="true"
                :clearable="false"
                :options="treeselectZoneEventOptions"
                auto-focus
                open-on-focus
                :open-direction="'above'"
                :default-expand-level="1"
                :normalizer="(node) => ({ label: node.name })"
                value-format="object"
                @close="onCloseZoneEventTreeselect"
              />
              <b-button
                v-else
                variant="outline-primary"
                size="sm"
                @click="onClickAddToZoneEvent"
              >
                {{ $t('menuCardAssignmentsAddButtonName') }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </template>
    <modal-confirm-delete
      v-model="isModalConfirmDelete"
      :title="`${$t('menuDeleteTitle')}`"
      :is-message="false"
      @delete="onDelete"
    />
    <modal-preview
      v-model="isModalPreview"
      :link="previewUrl"
    />
    <b-modal
      id="modal-zone-attach"
      v-model="isModalZoneEventAttach"
      centered
      hide-header
      hide-footer
    >
      <div>
        <h3 class="mb-2">
          {{
            restaurant && restaurant.eventsSettings.enabled
              ? $t('modalMenuPublishTitleZonesEvents')
              : $t('modalMenuPublishTitleZones')
          }}
        </h3>
        <b-form-group
          :label="$t('modalMenuPublishLabelZones')"
          class="mb-0"
        >
          <b-form-checkbox
            v-for="zone in zones"
            :key="zone.id"
            v-model="zonesIdToAttach"
            :value="zone.id"
            :disabled="isAttachingAndPublishing"
          >
            {{ zone.name }}
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div v-if="restaurant && restaurant.eventsSettings.enabled">
        <hr class="my-2">
        <b-form-group
          :label="$t('modalMenuPublishLabelEvents')"
          class="mb-0"
        >
          <b-form-checkbox
            v-model="isFutureEvent"
            :value="true"
            :disabled="isAttachingAndPublishing"
            @click="eventsIdToAttach = []"
          >
            {{ $t('modalMenuPublishEventFuture') }}
          </b-form-checkbox>
          <b-form-checkbox
            v-for="event in events"
            :key="event.id"
            v-model="eventsIdToAttach"
            :value="event.id"
            :disabled="isAttachingAndPublishing"
          >
            {{ event.name }}
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <b-button
          variant="primary"
          :disabled="isAttachingAndPublishing || (!zonesIdToAttach.length && !eventsIdToAttach.length && !isFutureEvent)"
          @click="attachAndPublish"
        >
          <template v-if="isAttachingAndPublishing">
            <b-spinner
              class="d-flex"
              small
            />
          </template>
          <template v-else>
            {{ $t('Publish') }}
          </template>
        </b-button>
        <b-button
          class="ml-1"
          variant="outline-primary"
          :disabled="isAttachingAndPublishing"
          @click="isModalZoneEventAttach = false"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import mixinMenu from '@/mixins/menu'
import MenuContentText from '@/components/MenuContentText.vue'

export default {
  name: 'Menu',
  components: { MenuContentText },
  mixins: [mixinMenu],
}
</script>

<style lang="sass">
  @import '@/assets/sass/content.sass'
  @import '@/assets/sass/lightgallery.sass'
  @import '@core/scss/vue/libs/vue-select.scss'
  @import '@/assets/sass/treeselect.sass'

  #modal-zone-attach
    .modal-body
      padding-top: 3rem
      padding-bottom: 3rem

  .card-assignments
    &__row
      &:hover
        background-color: $table-hover-bg
        .card-assignments__col_remove
          opacity: 1
    &__col
      &_remove
        opacity: 0
</style>
