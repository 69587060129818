<template>
  <b-row>
    <b-col>
      <div class="content-image">
        <div class="lightgallery">
          <a
            class="content-image__link"
            :href="`${VUE_APP_IMG_BASE}/${image.value}`"
          >
            <div
              class="content-image__thumbnail"
              :style="{ backgroundImage: `url(${VUE_APP_IMG_BASE}/${image.value})` }"
            />
          </a>
        </div>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
    >
      <div class="card-content__transparent">
        <feather-icon
          :class="[!isContentValid ? 'feather-disabled' : null]"
          role="button"
          size="20"
          icon="XCircleIcon"
          @click="$emit('delete')"
        />
        <icon-move-up-down :class="['ml-1 cursor-move', { 'disabled': !isContentValid }]" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import IconMoveUpDown from '@/components/icon/IconMoveUpDown.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuContentImage',
  components: {
    BRow,
    BCol,
    IconMoveUpDown,
  },
  props: {
    image: {
      type: Object,
      required: true,
      default: () => ({ id: '', type: 'Image', value: '' }),
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      VUE_APP_IMG_BASE: 'main/VUE_APP_IMG_BASE',
    }),
  },
}
</script>
