<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="icon-custom"
  >
    <polyline points="3 9 12 3 21 9" />
    <line
      x1="3"
      y1="12"
      x2="21"
      y2="12"
    />
    <polyline points="3 15 12 21 21 15" />
  </svg>
</template>

<script>
export default {
  name: 'IconMoveUpDown',
}
</script>
