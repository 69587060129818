<template>
  <b-row>
    <b-col>
      <div
        v-if="isEditMode"
        v-click-outside="onClickOutside"
      >
        <validation-provider
          #default="{ errors }"
          name="add_text"
          :vid="item.id"
          :rules="`max:${textLimit}`"
        >
          <b-form-textarea
            :value="item.value"
            max-rows="8"
            :disabled="!isContentValid && !errors.length"
            :placeholder="$t('Text')"
            :state="errors.length ? false : null"
            @input="$emit('textarea-input', $event)"
            @blur="$emit('textarea-blur')"
          />
          <small
            v-if="errors.length"
            class="text-danger"
          >
            {{ errors[0] }}
          </small>
          <div
            v-else
            class="text-right"
          >
            <small>{{ item.value.length }}/{{ textLimit }}</small>
          </div>
        </validation-provider>
      </div>
      <div
        v-else
        class="d-flex align-items-center"
      >
        <div
          :class="[{ 'cursor-pointer': isContentValid }]"
          @click="onClickText"
        >
          {{ item.value }}
        </div>
        <div class="d-flex card-content__transparent">
          <feather-icon
            :class="['ml-75 flex-shrink-0', { 'feather-disabled': !isContentValid }]"
            icon="Edit3Icon"
            role="button"
            size="20"
            @click="onClickIcon"
          />
        </div>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
      :style="{ paddingBottom: isEditMode ? '20px' : null }"
    >
      <div class="card-content__transparent">
        <feather-icon
          :class="[!isContentValid ? 'feather-disabled' : null]"
          role="button"
          size="20"
          icon="XCircleIcon"
          @click="$emit('delete')"
        />
        <icon-move-up-down :class="['ml-1 cursor-move', { 'disabled': !isContentValid }]" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import IconMoveUpDown from '@/components/icon/IconMoveUpDown.vue'
import { ValidationProvider } from 'vee-validate'
import { max } from '@validations'

export default {
  name: 'MenuContentText',
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign, func-names
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  components: {
    BRow,
    BCol,
    BFormTextarea,
    IconMoveUpDown,
    ValidationProvider,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({ id: '', type: 'Text', value: '' }),
    },
    textLimit: {
      type: Number,
      required: true,
      default: 250,
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      isEditMode: false,

      // validation rules
      max,
    }
  },
  created() {
    if (this.item.value.length === 0) {
      this.activeEditMode()
    }
  },
  methods: {
    activeEditMode() {
      if (!this.isEditMode && this.isContentValid) {
        setTimeout(() => {
          this.isEditMode = true
        })
      }
    },

    onClickText() {
      this.activeEditMode()
    },
    onClickIcon() {
      this.activeEditMode()
    },

    onClickOutside() {
      if (this.item.value.length && this.item.value.length <= this.textLimit) {
        this.isEditMode = false
      }
    },
  },
}
</script>
